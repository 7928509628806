import axios, { AxiosError, AxiosResponse } from 'axios'

import { UNAUTHORIZED_EVENT } from '~lib/constants/events'

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  // 'https://d47c-203-117-161-146.ngrok-free.app' ||
  'http://localhost:8080'

export const ApiService = axios.create({
  baseURL: `${API_BASE_URL}/v1/`,
  timeout: 100000, // 100 secs
  withCredentials: true,
})

export const getApiErrorMessage = (error: unknown): string => {
  const defaultErrMsg = 'Something went wrong'
  if (axios.isAxiosError(error)) {
    if (!error.response) return defaultErrMsg
    const response = error.response as AxiosResponse<
      { message: string } | undefined
    >
    return response?.data?.message ?? response?.statusText ?? defaultErrMsg
  }

  if (error instanceof Error) {
    return error.message ?? defaultErrMsg
  }

  return defaultErrMsg
}

export class ApiError extends Error {
  httpStatusCode?: number

  constructor({
    message,
    httpStatusCode,
  }: {
    message: string
    httpStatusCode?: number
  }) {
    super(message)
    this.httpStatusCode = httpStatusCode
  }
}

export const isApiError = (error: unknown): error is ApiError => {
  return error instanceof ApiError
}

export const isClientError = (error: unknown): error is ApiError => {
  return (
    isApiError(error) &&
    typeof error.httpStatusCode === 'number' &&
    error.httpStatusCode >= 400 &&
    error.httpStatusCode < 500
  )
}

export const isPermissionError = (error: unknown): error is ApiError => {
  return (
    isApiError(error) &&
    (error.httpStatusCode === 401 || error.httpStatusCode === 403)
  )
}

export class RateLimitError extends Error {
  retryAfter?: number

  constructor(error: AxiosError) {
    super()
    Object.setPrototypeOf(this, RateLimitError.prototype)

    this.name = 'RateLimitError'
    this.message = getApiErrorMessage(error)

    const headers = error.response?.headers
    if (headers) {
      // TODO: Fix typing
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.retryAfter = parseInt(headers['retry-after'])
    }
  }
}

export const isRateLimitError = (err: unknown): err is RateLimitError =>
  err instanceof Error && err.name === 'RateLimitError'

// TODO: Tidy up and standardize where we handle errors and error codes.
ApiService.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const status = error.response?.status
    if (status === 401) {
      // Event to let auth context know about 401s
      window.dispatchEvent(new Event(UNAUTHORIZED_EVENT))
    }

    if (status === 429) {
      throw new RateLimitError(error)
    }

    const transformedError = getApiErrorMessage(error)
    throw new ApiError({
      message: transformedError,
      httpStatusCode: status,
    })
  },
)
