import { PermissionsBasedRoute } from '~/app/PermissionsBasedRoute'
import { PrivateElement } from '~/app/PrivateElement'

import { DistributionsPage } from './DistributionsPage'

export const DistributionRoutes = () => {
  return (
    <PrivateElement>
      <PermissionsBasedRoute>
        <DistributionsPage />
      </PermissionsBasedRoute>
    </PrivateElement>
  )
}
