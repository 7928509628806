import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { routes } from '~constants/routes'
import { useCampaign } from '~hooks/useCampaign'
import { useAuth } from '~lib/auth'
import { isPermissionError } from '~lib/helpers/api'

type PermissionBasedRouteProps = {
  children: React.ReactNode
}

export const PermissionsBasedRoute = ({
  children,
}: PermissionBasedRouteProps) => {
  const { campaignId } = useParams() as { campaignId: string }
  const { user } = useAuth()
  const { error, isCampaignLoading } = useCampaign(campaignId)

  // We wait for fetching campaign data to be done to prevent redirect eagerly.
  // This is to ensure that we check whether the user does have permissions to
  // access this campaign or if the campaign does not exist.
  if (isCampaignLoading) {
    return null
  }
  // Only redirect to list of campaigns if fetching campaign data results in a 401 or 403 error.
  return user && isPermissionError(error) ? (
    <Navigate to={routes.index} />
  ) : (
    <>{children}</>
  )
}
