import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { QueryClient } from '@tanstack/react-query'

import { isClientError } from '~lib/helpers/api'

export const RETRIES_MAX = 3

/**
 * Generate query key constants to be used by react-query
 */
export const queryKeys = createQueryKeyStore({
  currentUser: null,
  selectedCampaign: null,
  selectedLocation: null,
  campaigns: {
    list: null,
    detail: (campaignId: string) => ({
      queryKey: [campaignId],
      contextQueries: {
        admins: {
          queryKey: null,
        },
        distributors: {
          queryKey: null,
        },
        allocations: {
          queryKey: null,
        },
        allocationsCount: {
          queryKey: null,
        },
        distributionCount: {
          queryKey: null,
        },
        locationCount: {
          queryKey: null,
        },
        locations: {
          queryKey: null,
          contextQueries: {
            detail: (locationId: string) => ({
              queryKey: [locationId],
              contextQueries: {
                stats: {
                  queryKey: null,
                },
              },
            }),
          },
        },
        statistics: {
          queryKey: null,
        },
      },
    }),
  },
  campaignOnboardingJobs: {
    list: null,
  },
  distributions: {
    list: null,
  },
})

export const queryClient = new QueryClient()

// Configure queryClient to skip retries for client errors
queryClient.setDefaultOptions({
  queries: {
    retry: (failureCount, error: unknown) => {
      return !isClientError(error) && failureCount < RETRIES_MAX
    },
  },
})
