import { PermissionsBasedRoute } from '~/app/PermissionsBasedRoute'
import { PrivateElement } from '~/app/PrivateElement'

import { StatisticsPage } from './StatisticsPage'

export const StatisticsRoutes = () => {
  return (
    <PrivateElement>
      <PermissionsBasedRoute>
        <StatisticsPage />
      </PermissionsBasedRoute>
    </PrivateElement>
  )
}
