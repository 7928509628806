import { PrivateElement } from '~/app/PrivateElement'

import { CampaignCreationPage } from './CampaignCreationPage'

export const CampaignCreationRoute = () => {
  return (
    <PrivateElement>
      <CampaignCreationPage />
    </PrivateElement>
  )
}
