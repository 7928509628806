import { PrivateElement } from '~/app/PrivateElement'

import { SettingsPage } from './SettingsPage'

export const SettingRoutes = () => {
  return (
    <PrivateElement>
      <SettingsPage />
    </PrivateElement>
  )
}
