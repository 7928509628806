import { Icon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  HStack,
  Image,
  MenuGroup,
} from '@chakra-ui/react'
import {
  AvatarMenu,
  AvatarMenuDivider,
  Link,
  Menu,
} from '@opengovsg/design-system-react'
import _ from 'lodash'
import React from 'react'
import { BiHelpCircle } from 'react-icons/bi'
import { HiChevronRight } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

import { CampaignStatus } from '~shared/constants/campaign'

import { StatusBadge } from '~components/Badge/StatusBadge'
import { CampaignOnboardingJobMenu } from '~components/CampaignOnboarding/CampaignOnboardingJobMenu'
import { routes } from '~constants/routes'
import { useAuth } from '~lib/auth'

interface NavbarProps {
  mode?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imgProps?: any
  campaignName?: string
  campaignId?: string
  campaignStatus?: CampaignStatus
}

export const Navbar = ({
  campaignName,
  campaignId,
  campaignStatus,
  imgProps,
  mode,
}: NavbarProps): JSX.Element => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const isAdminCampaign = !_.isUndefined(campaignId)

  const onLogOutClick = async () => {
    await logout()
    navigate(routes.login, { replace: true })
  }

  // TODO: Tidy this up
  const darkModeTextStyle = mode === 'dark' ? { color: 'white' } : {}
  const darkModeAvatarStyle =
    mode === 'dark' ? { bg: 'white', color: 'black' } : {}

  return (
    <Flex flexDir="row" justifyContent="space-between">
      <HStack spacing={4} alignItems="center">
        {imgProps ? (
          <Image {...imgProps} />
        ) : (
          <>
            <Image boxSize="2rem" src="/distribute-logoMark.svg" />
            <Breadcrumb
              spacing="8px"
              separator={<HiChevronRight color="gray.500" />}
            >
              <BreadcrumbItem isCurrentPage={!campaignName}>
                <BreadcrumbLink
                  href="/"
                  textStyle="subhead-2"
                  color="interaction.links.default"
                >
                  Distribute
                </BreadcrumbLink>
              </BreadcrumbItem>
              {campaignName && (
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink textStyle="subhead-2">
                    {campaignName}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
            </Breadcrumb>
            {campaignStatus && (
              <StatusBadge
                status={campaignStatus === 'active' ? 'success' : 'warning'}
                text={campaignStatus === 'active' ? 'Active' : 'Inactive'}
              />
            )}
          </>
        )}
      </HStack>

      <HStack spacing={8}>
        {isAdminCampaign && (
          <CampaignOnboardingJobMenu campaignId={campaignId} />
        )}
        <Link
          href="https://go.gov.sg/distribute-guide"
          sx={{ ...darkModeTextStyle }}
          externalLinkIcon={<Icon as={BiHelpCircle} fontSize="1.5rem" />}
          isExternal
          color="interaction.links.neutral-default"
        />
        <HStack>
          <AvatarMenu
            name={user?.email ?? user?.mobile ?? '?'}
            sx={{ ...darkModeAvatarStyle }}
            size="xs"
          >
            <React.Fragment key=".0">
              <MenuGroup title={user?.email ?? user?.mobile ?? ''} />
              <AvatarMenuDivider />
              <Menu.Item onClick={onLogOutClick}>Logout</Menu.Item>
            </React.Fragment>
          </AvatarMenu>
        </HStack>
      </HStack>
    </Flex>
  )
}
