import { PermissionsBasedRoute } from '~/app/PermissionsBasedRoute'
import { PrivateElement } from '~/app/PrivateElement'

import { DistributorsPage } from './DistributorsPage'

export const DistributorRoutes = () => {
  return (
    <PrivateElement>
      <PermissionsBasedRoute>
        <DistributorsPage />
      </PermissionsBasedRoute>
    </PrivateElement>
  )
}
