import { useQuery } from '@tanstack/react-query'
import camelcaseKeys from 'camelcase-keys'

import { GetCampaignResponseDto } from '~shared/types/campaign.dto'

import { queryKeys } from '~lib/constants/query'
import { ApiService } from '~lib/helpers/api'

export const useCampaign = (campaignId: string) => {
  const getCampaign = async () => {
    const { data } = await ApiService.get<GetCampaignResponseDto>(
      `/campaigns/${campaignId}`,
    )
    return data ? camelcaseKeys(data) : data
  }

  const {
    data: campaign,
    isLoading: isCampaignLoading,
    error,
  } = useQuery({
    ...queryKeys.campaigns.detail(campaignId),
    queryFn: getCampaign,
    refetchInterval: 180000, // 3mins
  })

  return {
    campaign,
    isCampaignLoading,
    error,
  }
}
